<template>
  <div class="sample_plan">
    <h2>Sample Plans</h2>
    <b-card>

      <b-form @submit="onSubmit">
      <b-row align-v="center">
        <b-col>
          <b-button @click="fetchMissingPlans">
          <span>Show Missing Plans</span>
        </b-button>
        </b-col>
      </b-row>

      <b-row align-v="center">
        <b-col>
          <b-form-group label="Status" label-align="left">
            <b-form-select
              v-model="plan_status"
              :options="status_options"
              @change="saveStatus"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <strong>Status Count:</strong>
          {{ sample_plans.length }}
        </b-col>
      </b-row>
      <b-row align-v="center">
        <b-col>
          <b-form-group label="PWS ID Filter" label-align="left" description="Press Enter or Refresh Table Data after change.">
            <b-form-input
              v-model="pws_id_filter"
              type="search"
              @change="savePWSIDFilter"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col>
          <b-button variant="primary" @click="fetchNewSamplePlans">
            Refresh Table Data</b-button
          >
        </b-col>

      </b-row>
    </b-form>
    </b-card>

    <b-modal id="pws-missing-modal" size="xl" ok-only title="PWS Missing Plans">
      <span>Count: {{ pws_missing.length }}</span>
      <b-table
        striped
        bordered
        responsive
        :items="pws_missing"
        :fields="pws_missing_fields"
      >

        <template #cell(has_submissions)="data">
          <b-button
            :disabled="!data.item.has_submissions"
            variant="primary"
            @click="setPWSFromModal(data.item.pws_id)"
            > {{ data.item.has_submissions }}</b-button
          >
        </template>

      </b-table>
    </b-modal>

    <!--
        :filter-included-fields="['pws_id']"
        :filter="pws_id_filter"
    -->
    <div class="mb-3 mt-3">
      <b-table
        v-if="sample_plans.length"
        ref="sample_plans_table"
        striped
        bordered
        responsive
        :fields="table_fields"
        :items="sample_plans"
      >
        <template #cell(plan_id)="data">
          <b-button
            size="sm"
            variant="primary"
            :to="{
              name: 'SamplePlanReview',
              params: { plan_id: data.item.plan_id }
            }"
            >Plan ID: {{ data.item.plan_id }}</b-button
          >
        </template>

        <template #cell(pws_id)="data">
          <b-button
            size="sm"
            variant="outline-primary"
            @click="setPWSFromModal(data.item.pws_id)"
            >{{ data.item.pws_id }}</b-button
          >
        </template>

        <template #cell(portal_user_email)="data">
          <b-button variant="outline-primary" size="sm"
            @click="createEmailLink(data.item)"
          >{{ data.item.portal_user_email }}</b-button>
        </template>

        <template #cell(status)="row">
          {{ status_options_map[row.item.status] ?  status_options_map[row.item.status]: row.item.status}}
        </template>

        <template #cell(comments)="row">
          <b-button
            v-if="row.item.comments"
            variant="primary"
            size="sm"
            @click="row.toggleDetails"
            class="mr-2"
          >
            {{ row.detailsShowing ? "Hide" : "Show" }} Comments
          </b-button>
        </template>

        <template #row-details="row">
          <b-card>
            <b-col><b>Comments:</b> &nbsp;{{ row.item.comments }}</b-col>
          </b-card>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      table_fields: [
        { key: "plan_id", label: "ID" },
        { key: "pws_id", label: "PWS ID", sortable: true},
        { key: "plan_type", label: "Type" },
        { key: "portal_user_email", label: "User" },
        { key: "data_signame", label: "Signed By" },
        { key: "comments", label: "Comments" },
        { key: "status", label: "Status" },
        { key: "submission_ts", label: "Submitted" },
        { key: "reviewer", label: "Reviewer" },
      ],
      pws_missing: [],
      pws_missing_fields: [
        { key: "pws_id", label: "PWS ID" },
        { key: "pws_name", label: "Name" },
        { key: "type_code", label: "Type Code" },
        { key: "has_submissions", label: "Made Submission(s)" },
      ],
      sample_plans: [],
      plan_status: "N",
      pws_id_filter: '',
      status_options: [
        { value: "ALL", text: "Show All Status" },
        { value: "N", text: "Not Reviewed" },
        { value: "A", text: "Reviewed" },
        { value: "F", text: "Followup Required" },
        { value: "R", text: "Rejected" },
        { value: "X", text: "Replaced" }
      ],
      // ugg - in a hurry
      status_options_map: {
        ALL: "Show All Status",
        N: "Not Reviewed",
        A: "Reviewed",
        F: "Followup Required",
        R: "Rejected",
        X: "Replaced"
      },
    }
  },
  mounted() {
    //pass
    //this.fetchUsers()
    let saved_plan_status = this.$store.state.sample_plans_list_status
    console.log(saved_plan_status)
    if (this.$store.state.sample_plans_list_status) {
      this.plan_status = this.$store.state.sample_plans_list_status
      console.log(this.plan_status)
    }
    if (this.$store.state.sample_plans_list_pws_id) {
      this.pws_id_filter = this.$store.state.sample_plans_list_pws_id
      console.log(this.plan_status)
    }
    this.fetchNewSamplePlans()
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault()
      this.fetchNewSamplePlans()
    },
    saveStatus() {
      console.log(this.plan_status)
      this.$store.commit('set_sample_plans_list_status', this.plan_status)
      this.fetchNewSamplePlans()
    },
    savePWSIDFilter() {
      console.log('saving pws id')
      this.$store.commit('set_sample_plans_list_pws_id', this.pws_id_filter)
    },
    setPWSFromModal(pws_id) {
      this.pws_id_filter = pws_id
      this.$bvModal.hide('pws-missing-modal')
      if (this.plan_status !== 'ALL') {
        this.plan_status = 'ALL'
      }
      this.fetchNewSamplePlans()
    },
    async fetchNewSamplePlans() {
      const path = process.env.VUE_APP_API_URI + "/sample-plans"
      try {
        console.log("fetching new sample plans")
        let plan_status = this.plan_status
        let pws_id = this.pws_id_filter
        if (plan_status == 'ALL') {plan_status = ''}
        let response = await this.axios.get(
          path, 
          { params: { status: plan_status, pws_id: pws_id } }
        )
        if (response.data) {
          this.sample_plans = response.data.sample_plans
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchMissingPlans() {
      this.$bvModal.show('pws-missing-modal')
      const path = process.env.VUE_APP_API_URI + "/sample-plans-missing"
      try {
        console.log("fetching missing plans")
        let response = await this.axios.get(path)
        if (response.data) {
          this.pws_missing = response.data.pws_missing
        }
      } catch (error) {
        console.log(error)
      }
    },
    createEmailLink(dataset) {
      let address = dataset.portal_user_email
      let pws_id = dataset.pws_id
      let pws_name = dataset.pws_name
      let signame = dataset.data_signame
      let submission_ts = dataset.submission_ts
      let subject = `RTCR Site Plan for ${pws_name}, ID# ${pws_id}`
      let msg = "This e-mail concerns the RTCR Sample Site Plan "
      msg += `submitted by ${signame} on ${submission_ts} `
      msg += ` for the Public Water System ${pws_name}, ID# ${pws_id}.\n\n`
      msg += "View, amend, or submit plans at the MS PWS Portal https://pws.mswater.us"
      let link = 'mailto:' + address + '?subject=' + encodeURIComponent(subject) + "&body=" + encodeURIComponent(msg)
      window.location.href=link
      //return link
    },
  }
}
</script>
